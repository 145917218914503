import logo from './logo.svg';
import './App.css';
import Home from './Home/Home';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div >
      <ToastContainer />
      <Home />
    </div>
  );
}

export default App;
