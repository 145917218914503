import React, { useState, useEffect } from 'react';
import { Grid, Box, CircularProgress } from '@mui/material';
import mainImg from '../Images/dar_al_mona_front.jpeg'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import handelErrors from '../components/helpers/handelErrors';

const Home = () => {
    const [data, setData] = useState([])
    const [prices, setPrices] = useState([])
    const [visible, setVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingPrices, setIsLoadingPrices] = useState(false)

    // const getPrices = (ID) => {
    //     setIsLoadingPrices(true);
    //     axios.get(`${process.env.REACT_APP_API_BASE_URL}/public/getPrices`, {
    //     })
    //         .then(function (response) {
    //             setPrices(response.data);
    //             setIsLoadingPrices(false);
    //         })
    //         .catch(function (error) {
    //             handelErrors(error);
    //             setIsLoadingPrices(false);
    //         })
    // }
    const getTicket = (ID) => {
        setIsLoading(true);
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/public/getTicket`, {
            params: {
                transaction_id: ID,
            }
        })
            .then(function (response) {
                setData(response.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                handelErrors(error);
                setIsLoading(false);
            })
    }


    useEffect(() => {
        // getPrices();
        const getUrlParameter = (name) => {
            const params = new URLSearchParams(window.location.search);
            return params.get(name);
        };
        const idFromUrl = getUrlParameter('ID');
        if (idFromUrl) {
            getTicket(idFromUrl);
        }
    }, []);


    return (
        <Grid container>
            <Grid xs={12} style={{
                borderBottom: '2px solid grey'
                , padding: '15px'
                , boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)'
            }}>
                <span style={{
                    fontSize: '22px'
                    , fontWeight: 'bold'
                    , textTransform: 'capitalize'
                    , color: '#5865F2'
                }}>dar al mona parking</span>
            </Grid>
            <Grid xs={12} style={{ minHeight: '100px', textAlign: "center", paddingTop: '20px' }}>
                {
                    isLoading ? <CircularProgress />
                        :
                        data?.status == 'created' ?
                            <>
                                <Box sx={{ marginTop: '5px', fontSize: '30px', fontWeight: 'bold', textDecoration: 'underline' }}>Amount Due:</Box>
                                <Box sx={{ marginTop: '5px', color: '#5865F2', fontSize: '30px', fontWeight: 'bold' }}>{data?.paid_amount} L.L</Box>
                            </>
                            : data?.status == 'paid' ?
                                <>
                                    <Box sx={{ marginTop: '5px', fontSize: '30px', fontWeight: 'bold', textDecoration: 'underline' }}>Your receipt has been paid.</Box>
                                    <Box sx={{ marginTop: '5px', color: '#5865F2', fontSize: '30px', fontWeight: 'bold' }}>{data?.paid_amount} L.L</Box>
                                </>
                                : null
                }
            </Grid>
            <Grid xs={12} style={{ minHeight: '100px', textAlign: "center", paddingTop: '20px' }}>
                <Box sx={{ marginTop: '5px', fontSize: '30px', fontWeight: 'bold', textDecoration: 'underline' }}>Enter Time</Box>
                <Box sx={{ marginTop: '5px', color: '#5865F2', fontSize: '30px', fontWeight: 'bold' }}>{data?.entry_str}</Box>
            </Grid>
            {
                data?.exit_str && (
                    <Grid xs={12} style={{ minHeight: '100px', textAlign: "center", paddingTop: '20px' }}>
                        <Box sx={{ marginTop: '5px', fontSize: '30px', fontWeight: 'bold', textDecoration: 'underline' }}>Exit Time</Box>
                        <Box sx={{ marginTop: '5px', color: '#5865F2', fontSize: '30px', fontWeight: 'bold' }}>{data?.exit_str}</Box>
                    </Grid>
                )
            }
            {/* <Grid xs={12}>
                <Box sx={{ padding: '30px' }}>
                    <Box sx={{ textAlign: 'center', fontSize: '32px' }}>
                        Daily <span style={{ color: '#5865F2', fontWeight: 'bold', textTransform: 'capitalize' }}>Rate</span>
                    </Box>
                </Box>
            </Grid>
            {
                isLoadingPrices ? <CircularProgress />
                    :
                    <Grid xs={12} style={{ display: 'flex', flexDirection: 'row', minHeight: '100px', paddingTop: '15px' }}>
                        <Box sx={{ width: '50%', borderRight: '3px solid #5865F2', textAlign: 'center' }}>
                            <p style={{ textDecoration: 'underline', fontSize: '18px', fontWeight: 'bold' }}>Weekdays</p>
                            <Box sx={{ width: '100%', minHeight: '100px', textAlign: 'left' }}>
                                <ul>
                                    {
                                        prices?.map((e, index) => {
                                            return (
                                                <li style={{ padding: '5px' }}>{e.parking_type_name}: <span>{e.price_value}</span></li>
                                            )
                                        })
                                    }
                                </ul>
                            </Box>
                        </Box>
                        <Box sx={{ width: '50%', textAlign: 'center' }}>
                            <p style={{ textDecoration: 'underline', fontSize: '18px', fontWeight: 'bold' }}>Weekend</p>
                            <Box sx={{ width: '100%', minHeight: '100px', textAlign: 'left' }}>
                                <ul>
                                    {
                                        prices?.map((e, index) => {
                                            return (
                                                <li style={{ padding: '5px' }}>{e.parking_type_name}: <span>{e.weekend_price_value}</span></li>
                                            )
                                        })
                                    }
                                </ul>
                            </Box>
                        </Box>
                    </Grid>
            } */}
            <Grid xs={12}>
                <Box sx={{ width: { xs: '90%', md: "50%" }, mr: 'auto', ml: 'auto', textAlign: 'left', pt: '20px' }}>
                    The management accepts no liability for loss or damage caused to any vehicle or its contents in this car park.
                </Box>
            </Grid>
        </Grid>
    )
}
export default Home